<template>
  <div class="footer">
    <v-card color="background" min-height="196" flat>
      <v-container class="py-5 px-4 px-md-3">
        <v-row>
          <!-- Left -->
          <v-col cols="12" md="4">
            <div class="d-flex">
              <router-link :to="{ name: 'HomePage' }">
                <v-img
                  alt="Logo"
                  class="logo"
                  contain
                  src="@/assets/img/public/shared/logos/logo.svg"
                  max-width="134"
                />
              </router-link>
            </div>

            <p class="mt-4 mt-md-11 b-caption gray700--text">
              © 2016. “Lime Loans South Africa” (PTY) LTD <br />
              Company no 2015/239349/07 <br />

              The National Credit Regulator – Registration Number NCRCP8077
            </p>
          </v-col>

          <!-- Center -->
          <v-col cols="12" md="4" class="mt-n7 mt-md-0">
            <p class="b-caption gray700--text">
              Our site uses cookies to provide services in accordance with the
              Privacy Policy. By using this site, you agree to our use of
              cookies
            </p>
            <p class="b-caption gray700--text mb-2 mt-2">
              C505, Bridgewater 3, Century City, Cape Town, 7441
            </p>

            <v-row class="pa-3">
              <v-col cols="12" md="6" class="ma-0 pa-0">
                <div>
                  <router-link
                    :to="{ name: 'PrivacyPolicyPage' }"
                    class="b-caption primary--text text-decoration-none"
                  >
                    Privacy Policy
                  </router-link>
                </div>
                <div>
                  <a
                    :href="termsAndConditionsUrl"
                    class="b-caption primary--text text-decoration-none"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>
                </div>
                <div>
                  <router-link
                    :to="{ name: 'ResponsibleLendingPage' }"
                    class="b-caption primary--text text-decoration-none"
                  >
                    Responsible Lending
                  </router-link>
                </div>
              </v-col>

              <v-col cols="12" md="6" class="ma-0 pa-0">
                <div>
                  <a
                    :href="accessRequestFormUrl"
                    class="b-caption primary--text text-decoration-none"
                    target="_blank"
                  >
                    Access Request Form
                  </a>
                </div>
                <div>
                  <a
                    :href="PAIAManualUrl"
                    class="b-caption primary--text text-decoration-none"
                    target="_blank"
                  >
                    PAIA Manual
                  </a>
                </div>

                <div>
                  <a
                    :href="refundPolicyUrl"
                    class="b-caption primary--text text-decoration-none"
                    target="_blank"
                  >
                    Refund Policy
                  </a>
                </div>
              </v-col>
            </v-row>
          </v-col>

          <!-- Right -->
          <v-col cols="12" md="4" class="mt-n4 mt-md-0">
            <!-- Email -->
            <div class="d-flex">
              <a
                href="mailto:support@lime24.co.za"
                class="text-decoration-none"
                target="_blank"
              >
                <div class="d-flex align-center">
                  <v-img
                    alt="Envelope"
                    class="envelope"
                    contain
                    src="@/assets/img/public/shared/footer/envelope.svg"
                    max-width="20"
                  />

                  <h6 class="b-subtitle-1 ml-2 primary--text">
                    support@lime24.co.za
                  </h6>
                </div>
              </a>
            </div>

            <!-- Phone -->
            <div class="d-flex mt-2">
              <a
                href="tel:+27104426722"
                class="text-decoration-none"
                target="_blank"
              >
                <div class="d-flex align-center">
                  <v-img
                    alt="Call"
                    class="call"
                    contain
                    src="@/assets/img/public/shared/footer/phone.svg"
                    max-width="18"
                  />

                  <h6 class="b-subtitle-1 ml-2 primary--text">010 442 6722</h6>
                </div>
              </a>
            </div>

            <!-- Facebook and Youtube -->
            <div class="d-flex mt-2 mb-2">
              <!-- Facebook -->
              <a
                href="https://www.facebook.com/lime24.co.za/"
                class="text-decoration-none"
                target="_blank"
              >
                <v-img
                  alt="Facebook"
                  class="facebook"
                  contain
                  src="@/assets/img/public/shared/footer/facebook.svg"
                  max-width="18"
                />
              </a>

              <!-- Youtube -->
              <a
                href="https://www.youtube.com/channel/UCjWjMdiMzCmzouIp2fK8hkA"
                class="text-decoration-none"
                target="_blank"
              >
                <v-img
                  alt="Youtube"
                  class="youtube ml-6"
                  contain
                  src="@/assets/img/public/shared/footer/youtube.svg"
                  max-width="20"
                />
              </a>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "LimeLoansTheFooter",

  data() {
    return {
      PAIAManualUrl:
        process.env.VUE_APP_APP_URL + "/assets/docs/Lime Loans PAIA Manual.pdf",
      accessRequestFormUrl:
        process.env.VUE_APP_APP_URL +
        "/assets/docs/Lime Loans South Africa (Pty) Ltd - Access Request Form.pdf",
      privacyPolicyUrl:
        process.env.VUE_APP_APP_URL +
        "/assets/docs/Lime Loans South Africa Privacy Policy.pdf",
      termsAndConditionsUrl:
        process.env.VUE_APP_APP_URL +
        "/assets/docs/Lime Loans South Africa Website Terms and Conditions.pdf",
      refundPolicyUrl:
        process.env.VUE_APP_APP_URL +
        "/assets/docs/Lime Loans South Africa (Pty) Ltd - Refund Policy V1.0.pdf",
    };
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.footer {
  @media only screen and (max-width: 600px) {
    .logo {
      width: 80px;
    }
  }
}
</style>
